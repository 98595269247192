module.exports = [{
      plugin: require('../node_modules/gatsby-background-image-es5/gatsby-browser.js'),
      options: {"plugins":[],"specialChars":"/:"},
    },{
      plugin: require('../node_modules/gatsby-background-image/gatsby-browser.js'),
      options: {"plugins":[],"specialChars":"/:"},
    },{
      plugin: require('../node_modules/gatsby-plugin-prismic-previews/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"thec3","accessToken":"MC5YR003WEJBQUFDTUFPYllz.77-9eU5n77-977-9aO-_ve-_ve-_vXk377-9Q--_vWcp77-9P--_vX7vv71M77-977-9JO-_ve-_ve-_vWNtGA","promptForAccessToken":true,"apiEndpoint":"https://thec3.cdn.prismic.io/api/v2","lang":"*","pageSize":100,"imageImgixParams":{"auto":"compress,format","fit":"max"},"imagePlaceholderImgixParams":{"w":100,"blur":15},"toolbar":"new"},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"The C3 Church","short_name":"The C3","description":"The C3 Church is an evangelical, charismatic Christian church based in Cambridge, Bury St Edmunds, Colchester, England and online.","start_url":"/","background_color":"#f05356","theme_color":"#202945","display":"standalone","icon":"static/manifest-logo.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"9be49716795ca4b2cf34088238dc04a1"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
